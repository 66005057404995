<template>
  <tbody>
  <tr v-if="!inLesson">
    <td class="p-0" colspan="10000">
      <div @click.prevent="$emit('toggleOpen')"
           class=" is-fullwidth level is-mobile p-1 is-clickable">
        <div class="level-left">
          <div class="level-item">
            <h3 class="is-size-4 has-text-primary">{{ topic.name }}</h3>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <figure>
              <p class="has-text-centered mt-2">

                <b-icon class="has-text-black" :icon="isOpen?$tc('icons.menu-up'):$tc('icons.menu-down')"></b-icon>
              </p>
            </figure>
          </div>
        </div>
      </div>
    </td>
  </tr>
  <template v-if="isOpen">
    <template v-for="(unit,index) in topic.units">
      <UnitItem :unit="unit" @toggle="toggleUnitOpen"
                :key="'topic '+ topicIndex + ' unit '+ index"></UnitItem>
      <template v-if="unit.isOpen">
        <ProgressObjectiveItem
            :guardian-mode="guardianMode"
            v-for="(objective,objectiveIndex) in unit.objectives" :objective="objective"
            :key="'topic '+ topicIndex + ' unit '+ index + ' objective ' + objectiveIndex"
            :topicIndex="topicIndex"
            :unitIndex="index"
            :objectiveIndex="objectiveIndex"
        ></ProgressObjectiveItem>
      </template>
    </template>
  </template>
  </tbody>

</template>

<script>
import UnitItem from "@/components/progressTracker/UnitItem";
import ProgressObjectiveItem from "@/components/progressTracker/ObjectiveItem";
import ILPUnit from "@/models/ILPUnit";

export default {
  name: "ProgressTopicItem",
  components: {UnitItem, ProgressObjectiveItem},
  data() {
    return {
    }
  },
  methods: {
    toggleUnitOpen(unit) {
      ILPUnit.update({
        where: (item) => {
          return item.isOpen
        },
        data:{
          isOpen:false
        }
      })
      ILPUnit.update({
        where: unit.id, data: {
          isOpen: !unit.isOpen
        }
      })
    }
  },
  props: {
    guardianMode:{
      type:Boolean,
      default() {
        return false
      }
    },   isOpen:{
      type:Boolean,
      default() {
        return false
      }
    },
    inLesson:{
      type:Boolean,
      default() {
        return false
      }
    },
    topic: {
      type: Object,
      required: true,
    },
    topicIndex: {
      type: Number,
      default() {
        return Math.random()
      }
    }
  },

};
</script>
