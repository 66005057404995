<template>
  <div class="is-relative" @mouseup="stopSelecting">
    <!--    <div class="columns">-->
    <!--      <div class="column">-->
    <!--        <b-field grouped>-->
    <!--          <b-field expanded label="Search" horizontal>-->
    <!--            <b-input-->
    <!--                v-debounce:300ms="getFilteredServices"-->
    <!--                placeholder="Search"-->
    <!--                :loading="loadingFilter"-->
    <!--                v-model="searchHold"-->
    <!--            ></b-input>-->
    <!--          </b-field>-->
    <!--          <b-field v-if="canCreate">-->
    <!--            <b-button-->
    <!--                @click="startCreatingService()"-->
    <!--                type="is-primary"-->
    <!--                :icon-right="$tc('icons.create')"-->
    <!--            >Create new Service-->
    <!--            </b-button>-->
    <!--          </b-field>-->
    <!--        </b-field>-->
    <!--      </div>-->
    <!--    </div>-->
    <PerfectScrollbarWrapper>

      <table class="table is-hoverable is-fullwidth progress-table">
        <ProgressTableHeader v-if="!guardianMode" :students="progressItems.students"></ProgressTableHeader>
        <!--      <tfoot>-->
        <!--      <tr>-->
        <!--        <th><abbr title="Position">Pos</abbr></th>-->
        <!--        <th>Team</th>-->
        <!--        <th><abbr title="Played">Pld</abbr></th>-->
        <!--        <th><abbr title="Won">W</abbr></th>-->
        <!--        <th><abbr title="Drawn">D</abbr></th>-->
        <!--        <th><abbr title="Lost">L</abbr></th>-->
        <!--        <th><abbr title="Goals for">GF</abbr></th>-->
        <!--        <th><abbr title="Goals against">GA</abbr></th>-->
        <!--        <th><abbr title="Goal difference">GD</abbr></th>-->
        <!--        <th><abbr title="Points">Pts</abbr></th>-->
        <!--        <th>Qualification or relegation</th>-->
        <!--      </tr>-->
        <!--      </tfoot>-->
        <TopicItem v-for="(topic,index) in progressItems.topics" :key="index"
                   :guardian-mode="guardianMode" :in-lesson="inLesson"
                   :is-open="topic.id === openTopic"
                   :topic="topic"
                   :topic-index="index"
                   @stopSelecting="stopSelecting"
                   @toggleOpen="toggleTopicOpen(topic.id)"></TopicItem>
        <tbody>


        </tbody>
      </table>
    </PerfectScrollbarWrapper>
    <ProgressLegend v-if="!inLesson" :guardian-mode="guardianMode" @save="saveProgress"></ProgressLegend>
  </div>
</template>

<script>
import Service from "@/models/Service";
import ServiceForm from "@/components/services/ServiceForm";
import ILP from "@/models/ILP";
import TopicItem from "@/components/progressTracker/TopicItem";
import ProgressTableHeader from "@/components/progressTracker/ProgressTableHeader";
import ProgressType from "@/models/ProgressType";
import {mapState} from "vuex";
import ProgressLegend from "@/components/progressTracker/ProgressLegend";
import ILPStudentProgress from "@/models/ILPStudentProgress";
import PerfectScrollbarWrapper from "@/components/scrollbar/PerfectScrollbarWrapper";

export default {
  name: "ProgressTable",
  components: {
    TopicItem, ProgressTableHeader, ProgressLegend, PerfectScrollbarWrapper
  },
  data() {
    return {
      openTopic: this.progressItems ? this.progressItems.topics[0].id : null,
      isOpen: true,
      search: null,
      loadingFilter: false,
      progressDataHold: {students: [], topics: []},
      meta: Object,
      page: 1,
      use_modal: false,
      limit: 20,
      loadingData: false,
      searchHold: null,
      subject_id: this.defaultSubjectId
    };
  },
  props: {
    learner_id: {
      type: Number,
      default() {
        return null
      }
    }, defaultSubjectId: {
      type: Number,
      default() {
        return null
      }
    }, submitBitFlip: {
      type: Number,
      default() {
        return 0
      }
    },
    defaultClassGroupId: {
      type: Number,
      default() {
        return null
      }
    },
    inLesson: {
      type: Boolean,
      default() {
        return false;
      },
    },
    guardianMode: {
      type: Boolean,
      default() {
        return false;
      },
    },
    lessonId: {
      type: Number,
      default() {
        return null;
      },
    },
    canDelete: {
      type: Boolean,
      default() {
        return false;
      },
    },
    canCreate: {
      type: Boolean,
      default: false,
    },

  },

  methods: {
    toggleTopicOpen(id) {
      if (this.openTopic === id) {
        this.openTopic = null
      } else {
        this.openTopic = id
      }

    },
    saveProgress() {
      this.$store.dispatch('loader/show')
      let items = ILPStudentProgress.query().where('is_dirty', true).with('objective').with('progress_type').get()
      let saveItems = items.map(item => {
        return {
          objective_id: item.objective.objective_id,
          user_id: item.user_id,
          progress_type_id: item.progress_type.id
        }
      })
      ILP.SaveProgress(saveItems).then(() => {
        this.fetchILP()
      }).catch(err => {
        this.handleError(err)
      }).finally(() => {
        this.$store.dispatch('loader/hide')
        this.$emit('saved')
      })
    },
    stopSelecting() {
      this.$store.state.progressTable.selecting = false
      setTimeout(() => {
        this.$store.state.progressTable.dragOpen = false

      }, 500)

    },
    getTypeName(id) {
      return this.$store.state.services.notice_period_types.find(type => type.id === id).name
    },
    startDelete(service) {
      this.$buefy.dialog.confirm({
        title: `Deleting service`,
        confirmText: `Delete service`,
        hasIcon: true,
        type: "is-danger",
        message: `Are you sure you want to delete this service?`,
        onConfirm: () =>
            Service.Delete(service.id)
                .then(() => {
                  this.$buefy.snackbar.open(`Service deleted!`);
                })
                .catch((err) => {
                  this.handleError(err)
                }),
      });
    },
    startCreatingService() {
      this.$buefy.modal.open({
        parent: this,
        props: {
          inModal: true,
          edit: false,
          campus_id: this.$store.state.campus.selected_campus_id,
        },
        component: ServiceForm,
        hasModalCard: true,
        trapFocus: true,
      });
    },
    viewService(service) {
      this.$buefy.modal.open({
        parent: this,
        props: {
          inModal: true,
          edit: true,
          service: service,
          campus_id: this.$store.state.campus.selected_campus_id,
        },
        component: ServiceForm,
        hasModalCard: true,
        trapFocus: true,
      });
    },
    getFilteredServices(text) {
      this.search = text;
    },
    fetchILP() {
      this.$store.dispatch('loader/show')
      this.loadingData = true;
      this.progressDataHold = this.progressItems;
      ILP.deleteAll();
      (this.inLesson ? ILP.FetchAllByLesson({lesson_id: this.lessonId}) : (this.guardianMode ? ILP.FetchAllByUser({subject_id: this.subject_id}, this.learner_id) : ILP.FetchAllBySubject(
          this.filters
      ))).then(
          ({
             response: {
               data: {meta},
             },
           }) => {
            this.$store.dispatch('loader/hide')

            this.meta = meta;
            this.loadingData = false;
            this.progressDataHold = null;
            console.log(this.progressItems)
            this.openTopic = this.progressItems? this.progressItems.topics[0].id:null
          }
      );
    },
  },
  watch: {
    submitBitFlip(newValue) {
      if (newValue === 1) {
        this.saveProgress()
      }
    },
    target_type(newValue) {
      if (newValue !== null) {
        let start = Date.now()
        let students = this.progressItems.student_ids.slice(this.$store.getters['progressTable/selectStudentIndex1'], this.$store.getters['progressTable/selectStudentIndex2'] + 1)
        let unitObjectives = this.progressItems.topics[this.$store.state.progressTable.selectCellStartTopic].units[this.$store.state.progressTable.selectCellStartUnit].objectives
        let objectives = unitObjectives.slice(this.$store.getters['progressTable/selectObjectiveIndex1'], this.$store.getters['progressTable/selectObjectiveIndex2'] + 1)
        let objectiveIds = objectives.map(objective => {
          return objective.id
        })
        // let items = ILPStudentProgress.query().where('user_id', (value) => students.indexOf(value) !== -1).where('objective_parent_id', (value) => objectiveIds.indexOf(value) !== -1).get()
        // for (let i = 0; i < items.length; i++) {
        //   items[i].isDirty = true
        //   items[i].progress = newValue
        // }
        // ILPStudentProgress.update({
        //   data: items
        // })
        ILPStudentProgress.update({
          data: ILPStudentProgress.query().where('user_id', (value) => students.indexOf(value) !== -1).where('objective_parent_id', (value) => objectiveIds.indexOf(value) !== -1).get()
              .map(item => {
                item.is_dirty = true
                item.progress = newValue
                return item
              })
        })
        //
        // ILPStudentProgress.update({
        //   where: (item) => {
        //     return (students.indexOf(item.user_id) !== -1) && (objectiveIds.indexOf(item.objective_parent_id) !== -1)
        //   },
        //   data: {
        //     isDirty: true,
        //     progress: newValue
        //   }
        // })
        // ILPStudentProgress.update({
        //   where: (item) => {
        //     return students.includes(item.user_id) && objectiveIds.includes(item.objective_parent_id)
        //   },
        //   data: {
        //     isDirty: true,
        //     progress: newValue
        //   }
        // })
        console.log(Date.now() - start)
        this.$store.dispatch('loader/hide')
      }
    },
    filters() {
      this.fetchILP(this.page);
    },
  },
  computed: {
    ...mapState('progressTable', ['selecting', 'target_type']),
    filters() {
      return {
        ...(this.subject_id
            ? {
              subject_id: this.subject_id,
            }
            : {}),
        ...(this.defaultClassGroupId
            ? {
              class_group_id: this.defaultClassGroupId,
            }
            : {})
      };
    },
    st() {
      return this.$store.state.progressTable.selectCellStartStudentId
    },
    start() {
      return this.progressItems.student_ids.indexOf(this.st)
    },
    progressItems() {
      if (this.progressDataHold === null) {
        return ILP.query().withAllRecursive(4).first();
      } else {
        return this.progressDataHold;
      }
    },
    progressTypes() {
      return ProgressType.query().orderBy('ordinality').get()
    },
    edit_services() {
      return this.$store.getters["entities/user-permissions/find"](
          "edit services"
      );
    },
  },
  mounted() {
    ProgressType.FetchAll({page: 1, limit: 20})
    this.fetchILP();


  },
};
</script>
