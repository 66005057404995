<template>
  <div>
    <div v-if="showFilters" class="columns">
      <div class="column">
        <b-field grouped>
          <b-field expanded>
            <b-select v-model="stageSearchId" expanded
                      placeholder="Stage">
              <option
                  v-for="stage in stages"
                  :key="stage.id"
                  :value="stage.id"
              >{{ stage.id }} | {{ stage.name }}
              </option>
            </b-select>
          </b-field>
          <b-field expanded>
            <!--suppress HtmlUnknownAttribute -->

            <b-input v-model="searchHold" v-debounce:300ms="getFilteredSubjects"
                     :loading="loadingFilter"
                     expanded placehold="Search" placeholder="Search"></b-input>
          </b-field>
          <b-field>
            <b-button @click="clear()">
              Clear
            </b-button>
          </b-field>
        </b-field>
      </div>
    </div>
    <hr v-if="showFilters">
    <PerfectScrollbarWrapper>
      <b-table
          :bordered="false"
          :data="subjects"
          :hoverable="true"
          :loading="loadingData"
          :scrollable="false"
          :striped="true"
          @click="viewSubjectPlans"
          class="margin-top is-fullwidth is-clickable"
      >
        <template #empty>
          <div class="has-text-centered">No Subjects</div>
        </template>
        <!--          :backend-sorting="true"-->
        <!--          :default-sort="['first_name', 'asc']"-->
        <!--          @sort="onSort"-->
        <b-table-column
            v-slot="props"
            field="id"
            label="ID"
            numeric
            sortable
            width="40"
        >{{ props.row.id }}
        </b-table-column>
        <b-table-column
            v-slot="props"
            field="code"
            label="Code"
            numeric
            sortable
            width="40"
        >{{ props.row.code }}
        </b-table-column>
        <b-table-column
            v-slot="props"
            field="long_name"
            label="Name"
            sortable

        >{{ props.row.long_name }}
        </b-table-column>

        <!--          <b-table-column-->
        <!--              v-slot="props"-->
        <!--              sortable-->
        <!--              field="course_scope.learning_stream.name"-->
        <!--              label="Learning Stream"-->
        <!--          >{{ props.row.course_scope.learning_stream.name }}-->
        <!--          </b-table-column>-->
        <b-table-column
            v-if="!fetchByUser"
            v-slot="props"

            label="Syllabus"
        >{{ props.row.course_scope !== null ? props.row.course_scope.syllabus : '' }}
        </b-table-column>

      </b-table>
    </PerfectScrollbarWrapper>
    <b-pagination
        v-if="!fetchByUser"
        :current="page"
        :per-page="limit"
        :range-after="2"
        :range-before="2"
        :total="meta.total"
        aria-current-label="Current page"
        aria-next-label="Next page"
        aria-page-label="Page"
        aria-previous-label="Previous page"
        class="mt-4"
        v-on:change="setPage"
    ></b-pagination>
  </div>

</template>

<script>
import Campus from "@/models/Campus";
// import CourseScope from "@/models/CourseScope";
import CourseScopeForm from "@/components/courses/CourseScopeForm";
import Phase from "@/models/Phase";
import Stage from "@/models/Stage";
import {mapState} from "vuex";
import Subject from "@/models/Subject";
import PerfectScrollbarWrapper from "@/components/scrollbar/PerfectScrollbarWrapper";

export default {
  components: {PerfectScrollbarWrapper},
  name: "Subjects.index",
  data() {
    return {

      search: null,
      loadingFilter: false,
      subjectsDataHold: null,
      meta: Object,
      page: 1,
      use_modal: false,
      limit: this.fetchByUser ? 2000 : 20,
      loadingData: false,
      searchHold: null,
      phaseSearchId: null,
      stageSearchId: null
    };
  },
  props: {
    subjectSelectId: {
      type: Number,
      default() {
        return null
      }
    },
    showFilters: {
      type: Boolean,
      default() {
        return true
      }
    }, fetchByUser: {
      type: Boolean,
      default() {
        return false
      }
    },
    user_id: {
      type: Number,
      default() {
        return null
      }
    },
    canDelete: {
      type: Boolean,
      default() {
        return false;
      },
    },
    canCreate: {
      type: Boolean,
      default: false,
    },
    headerText: {
      type: String,
      default() {
        return "Courses";
      },
    },
    createText: {
      type: String,
      default() {
        return "Course";
      },
    },
    tabEntity: {
      type: String,
      default() {
        return "Subjects";
      },
    },

  },
  methods: {
    clear() {
      this.phaseSearchId = null
      this.stageSearchId = null
      this.search = null
      this.searchHold = null
    },
    onSort(field, order) {

      this.order_by = field;
      this.order_direction = order;
    },

    viewSubjectPlans(subject) {
      this.$emit('selected', subject)
    },
    viewCourse(user) {
      this.$cookies.set(
          `subject_filters`,
          {
            page: this.page,
            phaseSearchId: this.phaseSearchId,
            stageSearchId: this.stageSearchId
          },
          "1d"
      );
      if (this.use_modal) {
        this.$buefy.modal.open({
          parent: this,
          props: {
            inModal: true,
          },
          component: CourseScopeForm,
          fullScreen: true,
          trapFocus: true,
        });
      } else {
        this.$router.push(`/courses/edit/${user.id}`);
      }
    },
    getFilteredSubjects(text) {
      this.search = text;
      this.loadingFilter = true
    },
    setPage(pageNumber, initial = false) {
      this.loadingData = true;
      this.subjectsDataHold = this.courses;
      Subject.deleteAll();
      this.page = pageNumber;
      (this.fetchByUser ? Subject.FetchAllByUser(
          {
            page: this.page,
            limit: this.limit,
          },
          ['course_scope', 'course_scope.learning_stream'], this.user_id
      ) : Subject.FetchAll(
          {
            page: this.page,
            limit: this.limit,
          },
          this.filters, ['course_scope', 'course_scope.learning_stream']
      )).then(
          ({
             response: {
               data: {meta},
             },
           }) => {
            this.meta = meta ?? null;
            this.loadingFilter = false
            this.loadingData = false;
            this.subjectsDataHold = null;
            if (this.subjectSelectId !== null) {
              this.viewSubjectPlans(
                  Subject.query().find(this.subjectSelectId)
              )
            }
            if (!initial&&!this.fetchByUser) {
              this.$cookies.set(
                  `subject_filters`,
                  {
                    page: this.page,
                    phaseSearchId: this.phaseSearchId,
                    stageSearchId: this.stageSearchId
                  },
                  "1d"
              );
            }
          }
      );
    },
  },
  watch: {

    all_campuses(value) {
      if (value === true) {
        this.phaseSearchId = null
        this.stageSearchId = null
      }
    },
    selected_campus_id() {
      this.phaseSearchId = null
      this.stageSearchId = null
    },
    filters() {
      Promise.all([Stage.FetchAll({
        page: 1,
        limit: 999
      }, {
        ...(this.$store.state.campus.selected_campus_id && !this.$store.state.campus.all_campuses
            ? {campus_id: this.$store.state.campus.selected_campus_id}
            : {}), year: this.$store.state.dates.year
      }, ['stages'])]).then(() => {
        this.$store.dispatch("loader/hide");
        this.setPage(this.page, true);
      }).catch(
          err => {
            this.$store.dispatch("loader/hide");

            if (err.response.status === 422) {
              this.handleError(err)
            } else {
              this.handleError(err)
            }
          }
      );
    },
  },
  computed: {
    phase_filter: {
      set(val) {
        this.phaseSearchId = val
        this.stageSearchId = null
      }, get() {
        return this.phaseSearchId
      }
    },
    ...mapState('campus', ["selected_campus_id", "all_campuses"]),
    campuses() {
      return Campus.query().get();
    },
    phases() {
      return Phase.query().where((phase) => {
        return this.$store.state.campus.all_campuses ? true : phase.campus_id === this.$store.state.campus.selected_campus_id
      }).where('year', this.$store.state.dates.year).get()
    },
    stages() {
      return Stage.query().where((phase) => {
        return this.$store.state.campus.all_campuses ? true : phase.campus_id === this.$store.state.campus.selected_campus_id
      }).where('year', this.$store.state.dates.year).get()
    },
    filters() {
      return {
        ...(this.order_direction
            ? {
              order_direction: this.order_direction,
            }
            : {}),
        ...(this.order_by
            ? {
              order_by: this.order_by,
            }
            : {}), ...(this.search
            ? {
              search: this.search,
            }
            : {}),
        ...(this.phaseSearchId
            ? {
              phase_id: this.phaseSearchId,
            }
            : {}), ...(this.stageSearchId
            ? {
              stage_id: this.stageSearchId,
            }
            : {}),
        ...(this.$store.state.campus.selected_campus_id && !this.$store.state.campus.all_campuses
            ? {campus_id: this.$store.state.campus.selected_campus_id}
            : {}), year: this.$store.state.dates.year
      };
    },
    subjects() {
      if (this.subjectsDataHold === null) {
        return Subject.query().with('learning_stream').with('course_scope').with('topic').with('course_scope.learning_stream').get();
      } else {
        return this.subjectsDataHold;
      }
    },

  },
  mounted() {
    Subject.deleteAll()
    this.$store.dispatch("loader/show");
    if (this.$cookies.isKey(`subject_filters`)) {
      let filters = this.$cookies.get(`subject_filters`);
      this.page = filters.page;
      this.phaseSearchId = filters.phaseSearchId;
      this.stageSearchId = filters.stageSearchId
      this.order_by = filters.order_by
      this.order_direction = filters.order_direction
      this.$cookies.remove(`subject_filters`);
    }

    Promise.all([Stage.FetchAll({
      page: 1,
      limit: 999
    }, {
      ...(this.$store.state.campus.selected_campus_id && !this.$store.state.campus.all_campuses
          ? {campus_id: this.$store.state.campus.selected_campus_id}
          : {}), year: this.$store.state.dates.year
    }, ['stages'])]).then(() => {
      this.$store.dispatch("loader/hide");
      this.setPage(this.page, true);
    }).catch(
        err => {
          this.$store.dispatch("loader/hide");

          if (err.response.status === 422) {
            this.handleError(err)
          } else {
            this.handleError(err)
          }
        }
    );
  },
};
</script>
