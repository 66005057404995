<template>
  <div @click="toggleExpand"
       class="progress-legend is-clickable is-flex has-background-white  is-align-items-center p-2"
       :class="{'is-expanded':isExpanded}"

  >
    <template v-if="isExpanded">
      <b-button v-if="!guardianMode" type="is-primary" class="mr-3" @click.stop="$emit('save')">
        Save
      </b-button>
      <div v-for="type of progressTypes" class="is-flex mr-3 progress-type-button" :key="type.id">

        <div :class="['progress-type-' + type.symbol]" class="progress-type-button-icon mr-2"></div>
        <span
            class="has-text-black">{{ type.name }}</span>
      </div>

    </template>
    <template v-else>
      <div class="is-flex is-justify-content-space-between is-align-items-center"><span
          class="progress-type-button  has-text-centered is-flex"
          v-for="type of progressTypes"
          :key="type.id">
             <span :class="['progress-type-' + type.symbol]"
                   class="progress-type-button-icon has-text-black mr-3">{{ type.symbol }}</span>
      </span></div>
    </template>

      <b-icon class="stroke-width-one"
              :icon="!isExpanded?$tc('icons.double-chevron-right'):$tc('icons.double-chevron-left')"></b-icon>
  </div>
</template>

<script>
import ProgressType from "@/models/ProgressType";

export default {
  name: "ProgressLegend",

  data() {
    return {
      isExpanded: true,

    };
  },

  props:{
    guardianMode: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  methods: {
    toggleExpand() {
      this.isExpanded = !this.isExpanded
    }
  },
  computed: {

    progressTypes() {
      return ProgressType.query().orderBy('ordinality').get()
    },

  },

};
</script>
