<template>
  <tr @mouseover="startHoverRow">


    <ObjectiveProgressCell v-for="(student,index) in objective.student_progress" :key="student.user_id"
                           :guardian-mode="guardianMode"
                           :hover-row-active="hoverRowActive"
                           :objectiveIndex="objectiveIndex"
                           :student="student"
                           :studentIndex="index"
                           :topicIndex="topicIndex"
                           :unitIndex="unitIndex"></ObjectiveProgressCell>
    <td class="progress-objective-column" :class="{'for-guardian':guardianMode}">

      <div class="is-size-6 progress-objective" v-html="strippedName"></div>
    </td>

  </tr>


</template>

<script>
import ObjectiveProgressCell from "@/components/progressTracker/ObjectiveProgressCell";

export default {
  name: "ProgressObjectiveItem",
  data() {
    return {
      isOpen: true,
      isHovered: false,
    }
  },
  components: {ObjectiveProgressCell},
  methods: {
    startHoverRow() {
      this.$store.state.progressTable.hoverCellObjective = this.objectiveIndex
      if (!this.inLessonForm) {
        this.$store.state.progressTable.hoverCellUnit = this.unitIndex
        this.$store.state.progressTable.hoverCellTopic = this.topicIndex
      }
    },

  },
  computed: {
    strippedName() {
      return this.objective.name.replace(/<\/?strong>/g, "");
    },
    hoverRowActive() {
      if (this.inLessonForm) {
        return this.$store.state.progressTable.hoverCellObjective === this.objectiveIndex
      }
      return this.$store.state.progressTable.hoverCellObjective === this.objectiveIndex &&
          this.$store.state.progressTable.hoverCellUnit === this.unitIndex &&
          this.$store.state.progressTable.hoverCellTopic === this.topicIndex
    }
  },
  props: {
    guardianMode: {
      type: Boolean,
      default() {
        return false
      }
    },

    objective: {
      type: Object,
      required: true,
    },
    topicIndex: {
      type: Number,
      default() {
        return 0
      }
    }, unitIndex: {
      type: Number,
      default() {
        return 0
      }
    }, objectiveIndex: {
      type: Number,
      default() {
        return 0
      }
    },

  },

};
</script>
