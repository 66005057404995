<template>
  <transition name="fade" mode="out-in">
    <tr>
      <td class="p-0" colspan="10000">
        <div @click.prevent="$emit('toggle',unit)"
             class="border-top-grey is-fullwidth level is-mobile p-1 is-clickable">
          <div class="level-left">

            <div class="level-item ml-3">
              <h3 class="is-size-5 has-text-primary">{{ unit.name }}</h3>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item ">
              <figure>
                <p class="has-text-centered mt-2">

                  <b-icon class="has-text-black"
                          :icon="unit.isOpen?$tc('icons.menu-up'):$tc('icons.menu-down')"></b-icon>
                </p>
              </figure>
            </div>
          </div>
        </div>
      </td>
    </tr>

  </transition>

</template>

<script>

export default {
  name: "ProgressUnitItem",

  props: {
    unit: {
      type: Object,
      required: true,
    },

  },

};
</script>
