<template>
  <td
      class="has-text-centered progress-symbol progress-cell  is-unselectable"

  >
    <div @mousedown.self="startSelect()" @mouseover="mouseOverColumn()" class="progress-symbol-text"
         :class="[student.progress,{'is-hovered':showHoverTint},{'selected':showSelectTint},{'is-clickable':!guardianMode}]">
      <!--      {{-->
      <!--        student.progress-->
      <!--      }}-->
    </div>
    <b-dropdown
        @active-change="cancelSelect"
        :can-close="!dragOpen"
        v-if="selectEnd"
        ref="options"
        :mobile-modal="false"
        class="progress-type-menu"
        append-to-body
        :close-on-click="false"
    >
      <template #trigger>
        <a
            class="is-hidden is-unselectable"
        >
        </a>
      </template>
      <b-dropdown-item class="px-1 py-0 has-text-centered level is-mobile" custom aria-role="listitem">

        <div class="level-left"><a @click="setTypes(type.symbol)" class="progress-type-button level-item
" v-for="type of progressTypes" :key="type.id">
          <!--          <b-icon :class="['progress-type-' + type.symbol]" class="progress-type-button-icon"-->
          <!--                  :icon="'checkbox-blank-square'">-->
          <!--          </b-icon>-->
          <span :class="['progress-type-' + type.symbol]"
                class="progress-type-button-icon has-text-black">{{ type.symbol }}</span>
        </a></div>


      </b-dropdown-item>

    </b-dropdown>

  </td>


</template>

<script>
import {mapState} from "vuex";
import ProgressType from "@/models/ProgressType";

export default {
  name: "ObjectiveProgressCell",
  data() {
    return {
      isOpen: true,
      isHovered: false,
      selectEnd: false,
    }
  },

  methods: {
    cancelSelect(bool) {
      if (!bool) {
        this.$store.dispatch('progressTable/resetSelect')
      }
    },
    setTypes(type_id) {
      this.$store.dispatch('loader/show')
      setTimeout(() => {
        this.$store.state.progressTable.target_type = type_id
        this.$refs['options'].toggle()
      }, 1)


    },
    startSelect() {
      if (this.$store.state.progressTable.selecting === false && !this.guardianMode) {
        this.$store.state.progressTable.target_type = null
        this.$store.state.progressTable.selectCellStartObjective = this.objectiveIndex
        this.$store.state.progressTable.selectCellStartUnit = this.unitIndex
        this.$store.state.progressTable.selectCellStartTopic = this.topicIndex
        this.$store.state.progressTable.selectCellStartStudentId = this.student.user_id
        this.$store.state.progressTable.selectCellStartStudentIndex = this.studentIndex
        this.$store.state.progressTable.selectCellEndObjective = this.objectiveIndex
        this.$store.state.progressTable.selectCellEndUnit = this.unitIndex
        this.$store.state.progressTable.selectCellEndTopic = this.topicIndex
        this.$store.state.progressTable.selectCellEndStudentId = this.student.user_id
        this.$store.state.progressTable.selectCellEndStudentIndex = this.studentIndex
        this.$store.state.progressTable.selecting = true
      }
    },
    mouseOverColumn() {
      this.$store.state.progressTable.hoverCellStudentId = this.student.user_id
      if ((this.$store.state.progressTable.selectCellEndTopic !== null) && (this.$store.state.progressTable.selectCellEndUnit !== null)) {
        if ((this.$store.state.progressTable.selectCellEndTopic !== this.topicIndex) || (this.$store.state.progressTable.selectCellEndUnit !== this.unitIndex)) {
          this.$store.state.progressTable.selecting = false

          return
        }
      }
      if (this.$store.state.progressTable.selecting === true) {
        this.$store.state.progressTable.selectCellEndObjective = this.objectiveIndex
        this.$store.state.progressTable.selectCellEndUnit = this.unitIndex
        this.$store.state.progressTable.selectCellEndTopic = this.topicIndex
        this.$store.state.progressTable.selectCellEndStudentId = this.student.user_id
        this.$store.state.progressTable.selectCellEndStudentIndex = this.studentIndex
      }
    }
  },
  watch: {
    selecting(newValue) {
      if (this.$store.state.progressTable.hoverCellObjective === this.objectiveIndex &&
          this.$store.state.progressTable.hoverCellUnit === this.unitIndex &&
          this.$store.state.progressTable.hoverCellTopic === this.topicIndex &&
          this.$store.state.progressTable.selectCellEndStudentId === this.student.user_id) {
        if (newValue === false) {
          this.$store.state.progressTable.selecting = false
          this.selectEnd = true
          this.$nextTick(() => {
            this.$refs['options'].toggle()
            this.$store.state.progressTable.dragOpen = true

          })
        }
      }
    }
  },
  computed: {
    ...mapState('progressTable', ['selecting', 'dragOpen']),
    showHoverTint() {
      return (this.hoverRowActive || this.hoverColumnActive) && (!this.showSelectTint)
    },
    showSelectTint() {
      return ((((this.$store.state.progressTable.selectCellEndTopic === this.topicIndex) && (this.$store.state.progressTable.selectCellEndUnit === this.unitIndex)) && ((this.objectiveIndex >= this.$store.getters['progressTable/selectObjectiveIndex1'] && this.objectiveIndex <= this.$store.getters['progressTable/selectObjectiveIndex2']) && (this.studentIndex >= this.$store.getters['progressTable/selectStudentIndex1'] && this.studentIndex <= this.$store.getters['progressTable/selectStudentIndex2']))))
    },
    progressTypes() {
      return ProgressType.query().orderBy('ordinality').get()
    },
    hoverColumnActive() {
      return this.$store.state.progressTable.hoverCellStudentId === this.student.user_id
    },
  },
  props: {
    student: {
      type: Object,
      required: true,
    },
    guardianMode: {
      type: Boolean,
      default() {
        return false
      }
    },
    hoverRowActive: {
      type: Boolean,
      default() {
        return false
      }
    },
    topicIndex: {
      type: Number,
      default() {
        return 0
      }
    }, unitIndex: {
      type: Number,
      default() {
        return 0
      }
    }, objectiveIndex: {
      type: Number,
      default() {
        return 0
      }
    }, studentIndex: {
      type: Number,
      default() {
        return 0
      }
    },
  },

};
</script>
