<template>
  <thead>
  <tr>

    <ProgressHeaderName v-for="student in students" :key="student.id" :student="student"></ProgressHeaderName>
    <th class="progress-table-options-header ">

    </th>
  </tr>
  </thead>

</template>

<script>
import ProgressHeaderName from "@/components/progressTracker/ProgressHeaderName";

export default {
  name: "ProgressTableHeader",
  components: {ProgressHeaderName},
  data() {
    return {
      isOpen: true
    }
  },
  props: {
    students: {
      type: Array,
      required: true,
    },

  },

};
</script>
