<template>
  <th :class="{'is-hovered':hoverColumnActive}" class="progress-header-name-cell px-1"
      @mouseover="startHoverColumn()"><span
      class="progress-header-name">{{ clipContent}}</span></th>
</template>

<script>


import clip from "text-clipper";

export default {
  name: "ProgressHeaderName",
  props: {
    student: {
      type: Object,
      required: true,
    },

  },
  methods: {
    startHoverColumn() {
      this.$store.state.progressTable.hoverCellStudentId = this.student.id
    },
  },
  computed: {
    clipContent() {
      let name = this.student.first_name + ' ' + this.student.last_name
      return clip(name, 20, {
        indicator:'',
        html: false,
      });

    },
    hoverColumnActive() {
      return this.$store.state.progressTable.hoverCellStudentId === this.student.id
    }
  }
};
</script>
