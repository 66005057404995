<template>
  <form @submit.prevent="submit">

    <div :class="{'modal-card':inModal}" style="width: auto">
      <header v-if="inModal" class="modal-card-head">
        <p class="modal-card-title">{{ edit === false ? 'Create a new' : 'Edit' }} Service</p>
      </header>
      <section :class="{'modal-card-body':inModal}">
        <b-field label="Year">
          <b-select expanded required v-model="serviceObject.year" placeholder="Year">
            <option
                v-for="year of $store.getters['dates/years']"
                :key="year + 'year'"
                :value="year"
            >{{ year }}
            </option>
          </b-select>
        </b-field>
        <b-field label="Name">
          <b-input
              v-model="serviceObject.name"
              placeholder="Service name"
              required>
          </b-input>
        </b-field>
        <b-field expanded label="Phases">

          <b-dropdown
              append-to-body
              expanded
              v-model="serviceObject.phase_ids"
              multiple
              aria-role="list"
          >

            <b-button class="select" expanded slot="trigger"
                      :disabled="serviceObject.phase_ids===null"
            >
              Grade
            </b-button>

            <b-dropdown-item
                v-for="phase in phases"
                :type="{'is-hidden': serviceObject.phase_ids === null }"
                :key="phase.id"
                :value="phase.id"
                aria-role="listitem"
                required
            >
              <span>{{ phase.name }}</span>
            </b-dropdown-item>
          </b-dropdown>

        </b-field>

        <b-field label="Cost">
          <b-input
              v-model="serviceObject.cost"
              placeholder="Service cost"
              type="number"
              min="0"
          >
          </b-input>
        </b-field>
        <b-field label="Adhoc Cost">
          <b-input
              v-model="serviceObject.adhoc_cost"
              placeholder="Adhoc cost"
              type="number"
              min="0"
          >
          </b-input>
        </b-field>
        <b-field expanded label="Billing Period">
          <b-select expanded required v-model="serviceObject.billing_period">
            <option :key="index" v-for="(period,index) in $store.state.services.billing_periods" :value="period">
              {{ period }}
            </option>
          </b-select>
        </b-field>
        <b-field expanded label="Notice Period Type">
          <b-select expanded v-model="serviceObject.notice_period_type">
            <option :key="period.id" v-for="period in $store.state.services.notice_period_types" :value="period.id">
              {{ period.name }}
            </option>
          </b-select>
        </b-field>
        <b-field v-if="serviceObject.notice_period_type ===1" label="Notice Period (Days)">
          <b-numberinput v-model="serviceObject.notice_period" :min="1" :step="1">

          </b-numberinput>
        </b-field>
        <b-field v-if="serviceObject.notice_period_type ===2" label="Cutoff Day (Day of the Month)">
          <b-numberinput v-model="serviceObject.notice_cut_day" :min="1" :step="1" :max="28">

          </b-numberinput>
        </b-field>
        <b-field label="Is Open For Signups">
          <b-switch :true-value="1" :false-value="0" v-model="serviceObject.is_open">
            {{ serviceObject.is_open === 1 ? 'Yes' : 'No' }}
          </b-switch>
        </b-field>
        <b-field label="Has Attendances">
          <b-switch :true-value="1" :false-value="0" v-model="serviceObject.has_attendances">
            {{ serviceObject.has_attendances === 1 ? 'Yes' : 'No' }}
          </b-switch>
        </b-field>
        <b-field label="Has Adhoc Attendants">
          <b-switch :true-value="1" :false-value="0" v-model="serviceObject.has_ad_hoc_attendants">
            {{ serviceObject.has_ad_hoc_attendants === 1 ? 'Yes' : 'No' }}
          </b-switch>
        </b-field>
        <b-field :label="$tc('Teacher',2)">
          <b-table
              class="margin-top"
              :data="serviceObject.teachers"
              :striped="true"
              :hoverable="true"
              :bordered="true"
          >
            <template #empty>
              <div class="has-text-centered">No {{ $tc('Teacher', 2) }}</div>
            </template>
            <b-table-column
                v-slot="props"
                sortable
                field="first_name"
                label="First Name"
            >{{ props.row.first_name }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                sortable
                field="last_name"
                label="Last Name"
            >{{ props.row.last_name }}
            </b-table-column>
            <b-table-column v-slot="props" sortable field="email" label="Email">{{
                props.row.email
              }}
            </b-table-column>
            <b-table-column v-slot="props" narrowed centered custom-key="actions">
              <b-field grouped>
                <b-field>
                  <b-button @click="viewUser(props.row)" type="is-primary"
                  >View
                  </b-button
                  >
                </b-field>
                <b-field>
                  <b-tooltip label="Remove" size="is-small" type="is-danger">
                    <b-button
                        type="is-danger"
                        :icon-right="$tc('icons.minus')"
                        @click="removeTeacher(props.row)"
                    ></b-button>
                  </b-tooltip>
                </b-field>
              </b-field>
            </b-table-column>
          </b-table>

        </b-field>
        <b-field :label="`Add ${$tc('Teacher')}`">
          <UsersFilter :dropdown-direction="'top'" :with_permission="'create attendances'"
                       :type="'autocomplete'" @selected="selectUser"
                       :campus_id="serviceObject.campus_id"></UsersFilter>
        </b-field>
        <MediaPanel
            :submit_flip="submitMedia"
            @submitted="submitMedia=false"
            :submit_button="false"
            v-if="edit && loaded"
            :title="'Documents'"
            :has-tag="false"
            :canDelete="!!$store.getters['entities/user-permissions/find']('delete media')"
            :canCreate="!!$store.getters['entities/user-permissions/find']('create media')"
            :model="'services'"
            :model_id="serviceObject.id"
        ></MediaPanel>
      </section>
      <footer :class="{'modal-card-foot':inModal}">
        <button class="button" type="is-primary" :icon-right="$tc(`icons.${edit===false?'create':'edit'}`)">Save
        </button>
      </footer>
    </div>
  </form>
</template>

<script>
import UserProfile from "@/components/users/UserProfile";
import UsersFilter from "@/components/panelled-dash/UsersFilter";
import Service from "@/models/Service";
import MediaPanel from "@/components/media/MediaPanel";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import {editorConfig} from "@/editorConfig";
import Phase from "@/models/Phase";


export default {
  name: "ServiceForm",
  components: {
    UsersFilter, MediaPanel,

  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        ...editorConfig,
        ...{placeholder: "An optional description"},
      },
      submitMedia: false,
      loaded: false,
      serviceObject: {
        name: '',
        cost: null,
        has_attendances: 0,
        has_ad_hoc_attendants: 0,
        campus_id: this.campus_id,
        teachers: [],
        teachers_ids: [],
        phase_ids: [],
        billing_period: null,
        notice_period: null,
        is_open: 0,
        adhoc_cost: null,
        year: this.$store.state.dates.year,
        notice_period_type: 0,
        notice_cut_day: 10,

      }
    }
  },
  methods: {
    viewUser(user) {
      this.$buefy.modal.open({
        parent: this,
        props: {
          canCreateWard: !!this.$store.getters['entities/user-permissions/find']('edit guardians'),
          canDeleteWard: !!this.$store.getters['entities/user-permissions/find']('edit guardians'),
          canEditWard: !!this.$store.getters['entities/user-permissions/find']('edit guardians')
          ,
          inModal: true,
          tabs: [
            {title: "User Details", role: "details"},
          ],
          proppedUser: user,
          canEdit: !!this.$store.getters["entities/user-permissions/find"](
              "edit users"
          ),
          canDelete: !!this.$store.getters["entities/user-permissions/find"](
              "delete users"
          ),
        },
        component: UserProfile,
        fullScreen: true,
        trapFocus: true,
      })
    }
    ,
    removeTeacher(user) {
      this.serviceObject.teachers = this.serviceObject.teachers.filter(teacher => teacher.id !== user.id)
      this.serviceObject.teachers_ids = this.serviceObject.teachers_ids.filter(teacher_id => teacher_id !== user.id)
    },
    selectUser(user) {
      this.serviceObject.teachers.push(user)
      this.serviceObject.teachers_ids.push(user.id)
    },
    submit() {
      if (this.edit) {
        Service.Update({
          ...{
            id: this.serviceObject.id,
            name: this.serviceObject.name,
            cost: this.serviceObject.cost,
            has_attendances: this.serviceObject.has_attendances,
            has_ad_hoc_attendants: this.serviceObject.has_ad_hoc_attendants,
            campus_id: this.campus_id,
            teachers: this.serviceObject.teachers_ids,
            billing_period: this.serviceObject.billing_period,
            is_open: this.serviceObject.is_open,
            adhoc_cost: this.serviceObject.adhoc_cost,
            year: this.serviceObject.year,
            notice_period_type: this.serviceObject.notice_period_type,
            phases: this.serviceObject.phase_ids,

          }, ...(this.serviceObject.notice_period_type === 1 ? {notice_period: this.serviceObject.notice_period,
            notice_cut_day: 0
          } : {}), ...(this.serviceObject.notice_period_type === 2 ? {
            notice_cut_day: this.serviceObject.notice_cut_day,
            notice_period: 0,
          } : {}), ...(this.serviceObject.notice_period_type === 0 ? {
            notice_period: 0,
            notice_cut_day: 0
          } : {})
        }).then(() => {
          this.submitMedia = true
          Service.FetchById({id: this.serviceObject.id}, ['phases']).then(() => {
            this.$buefy.snackbar.open(`Service updated!`)
            this.$emit('close')
          })
        }).catch(err => {
          this.handleError(err)
        })
      } else {
        Service.Store({
          ...{
            name: this.serviceObject.name,
            cost: this.serviceObject.cost,
            has_attendances: this.serviceObject.has_attendances,
            has_ad_hoc_attendants: this.serviceObject.has_ad_hoc_attendants,
            campus_id: this.campus_id,
            teachers: this.serviceObject.teachers_ids,
            billing_period: this.serviceObject.billing_period,
            is_open: this.serviceObject.is_open,
            adhoc_cost: this.serviceObject.adhoc_cost,
            year: this.serviceObject.year,
            notice_period_type: this.serviceObject.notice_period_type,
            phases: this.serviceObject.phase_ids,

          }, ...(this.serviceObject.notice_period_type === 1 ? {notice_period: this.serviceObject.notice_period} : {}), ...(this.serviceObject.notice_period_type === 2 ? {
            notice_cut_day: this.serviceObject.notice_cut_day
          } : {}), ...(this.serviceObject.notice_period_type === 0 ? {
            notice_period: 0
          } : {})
        }).then(({entities: {services}}) => {
          this.submitMedia = true

          Service.FetchById({id: services[0].id}, ['phases']).then(() => {
            this.$buefy.snackbar.open(`Service created!`)
            this.$emit('close')
          })

        }).catch(err => {
          this.handleError(err)
        })
      }
    }
  }
  ,
  computed: {
    phases() {
      return Phase.query().where('year', this.serviceObject.year).where('campus_id', this.serviceObject.campus_id).get()
    }
  },
  mounted() {
    if (this.edit) {
      this.serviceObject = JSON.parse(JSON.stringify(this.service))
      this.loaded = true
    }
  }
  ,
  props: {
    campus_id: {
      type: Number,
      default
          () {
        return 0
      }
    },
    service:
        {
          type: Object,
          default
              () {
            return {
              name: ''
            }
          }
          ,
        }
    ,
    inModal: {
      type: Boolean,
      default
          () {
        return false
      }
    }
    ,
    edit: {
      type: Boolean,
      default
          () {
        return false
      }
    }
    ,
  }
}
</script>

