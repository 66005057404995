<template>
  <div>
    <CampusHeader
        :activeTab="activeTab"
        :can-create="false"
        :can-select="false"
        :can-select-wards="true"
        :can-select-year="false"
        :header-text="headerText"
        :header_tabs="tabs"
        :hide-tabs="true"
        :manage-campuses="false"
        :show-campus="false"
        :show-head="true"
        :show-mobile="false"
        @change-tab="changeTab"

    ></CampusHeader>
    <section class="section pt-4 box">
      <b-tabs expanded multiline  v-model="activeTab" :class="{'hidden-tabs':activeTabs.length<2}" :destroy-on-hide="true"
              @input="changeTab">
        <!--        <b-tab-item v-if="useTab('wards')">-->
        <!--          <div class=" columns">-->
        <!--            <div class="column is-12">-->
        <!--              <WardsIndex-->
        <!--                  :user_id="$store.state.user.id"-->
        <!--                  @selected="handleStudentSelect"-->
        <!--              ></WardsIndex>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </b-tab-item>-->
        <b-tab-item label="Subjects">
          <div class=" columns">
            <div class="column is-12">
              <SubjectsIndex v-if="selectedStudent!==null"
                             :fetch-by-user="true"
                             :show-filters="false"
                             :subject-select-id="$route.query.subject_id&&$route.query.tab==='progress-tracker'?parseInt($route.query.subject_id):null"
                             :user_id="selectedStudent.id"
                             @selected="option => handleSubjectSelect(option)"
              >
              </SubjectsIndex>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item v-if="selectedSubject!==null" label="Progress">
          <div class=" columns">
            <div class="column is-12">
              <ProgressTable
                  :default-subject-id="selectedSubject.id"
                  :guardian-mode="true"
                  :learner_id="selectedStudent.id"
              >
              </ProgressTable>
            </div>
          </div>
        </b-tab-item>
      </b-tabs>

    </section>
  </div>
</template>

<script>
import CampusHeader from "@/components/panelled-dash/CampusHeader";
import User from "@/models/User";
import SubjectsIndex from "@/components/lessonPlans/SubjectsIndex";
import ProgressTable from "@/components/progressTracker/ProgressTable";
import {mapState} from "vuex";
// import Enrolment from "@/models/Enrolment";

export default {
  name: "ProgressWardsIndex",
  components: {
    SubjectsIndex,
    // WardsIndex,
    CampusHeader,
    ProgressTable
  },
  data() {
    return {
      loaded: false,
      activeTab: 0,
      activeTabs: ['wards'],
      search: null,
      loadingFilter: false,
      userDataHold: null,
      meta: Object,
      page: 1,
      suppress_campus: true,
      limit: 20,
      loadingData: false,
      searchHold: null,
      selectedStudent: null,
      selectedSubject: null,
    };
  },
  props: {},
  watch: {
    selected_ward_id(newVal) {
      this.selectedStudent = null
      this.handleStudentSelect({id: newVal})
    }
  },
  methods: {
    changeTab(index) {
      this.$nextTick(() => {
        this.activeTab = index;

      })

      this.setQuery({tab: this.tabs[index].role})
    },
    handleSubjectSelect(subject, navigate = true) {
      // this.$store.dispatch('loader/show')
      this.activateTab('progress-tracker')
      this.selectedSubject = null
      this.selectedSubject = subject

      if (navigate) {
        this.setQuery({subject_id: subject.id})
        this.$nextTick((()=>{
          this.activeTab = 1

        }))
      }
      // if (this.$route.query.tab === 'subjects') {
      //   this.goToTabName('subjects')
      // }
      // if (this.$route.query.tab === 'progress-tracker') {
      //   this.goToTabName('progress-tracker')
      // }
      return this.$store.dispatch('loader/hide')


    },

    handleStudentSelect(student, navigate = true) {
      this.selectedStudent = null
      this.$store.dispatch('loader/show')

      return User.FetchById(student.id).then(() => {
        this.activateTab('subjects')
        this.deactivateTab('progress-tracker')
        this.selectedStudent = User.query().find(student.id)
        if (navigate) {
          this.setQuery({ward_id: student.id})
          this.goToTabName('subjects')
          this.selectedSubject = null
        }
        this.$store.dispatch('loader/hide')

      }).catch(err => {
        this.handleError(err)
      })


    },
    goToTabName(tabName) {
      this.activeTab = this.tabs.findIndex(tab => {
        return tab.role === tabName
      })

      this.setQuery({tab: tabName})

    },
    activateTab(tabName) {
      if (!this.activeTabs.includes(tabName)) {
        this.activeTabs.push(tabName)
      }
    },
    deactivateTab(tabName) {
      this.activeTabs = this.activeTabs.filter(tab => tab !== tabName)
    },
    useTab(role) {
      return this.tabs.some((tab) => tab.role === role);
    },
  },

  computed: {
    ...mapState('campus', ['selected_ward_id']),
    headerText() {
      if (this.selectedStudent === null) {
        return 'Wards'
      }
      if (this.activeTab===1 && this.selectedSubject!==null){
        return `${this.selectedStudent.first_name} / ${this.selectedSubject.name??''}`

      }
      return `${this.selectedStudent.first_name}`
    },
    tabs() {
      let tabs = []

      if (this.activeTabs.includes('subjects')) {
        tabs.push({name: 'Subjects', title: 'Subjects', role: 'subjects'})
      }
      if (this.activeTabs.includes('progress-tracker')) {
        tabs.push({name: 'Progress Tracker', title: 'Progress Tracker', role: 'progress-tracker'})
      }
      return tabs
    },
  },
  mounted() {
    if (this.$route.query.ward_id) {
      this.handleStudentSelect({id: this.$route.query.ward_id}, false).then(() => {
        if (this.$route.query.tab) {
          if (this.$route.query.subject_id) {
            // console.log('s')
            // this.handleSubjectSelect({id: this.$route.query.subject_id}, true)
          } else {
            if (this.$route.query.tab === 'subjects') {
              this.activeTab = 0

            }
          }


        }
      })
    }
  },
};
</script>
